<template>
  <div v-if="!isLoading">
    <p class="text-start font-weight-bolder font-medium-5">
      {{ isAvailID ? $t('Update Discount') : $t('Add a Discount') }}
    </p>
    <error-alert
      :fields="discountsFields"
      :error="error"
    />
    <b-form>
      <validation-observer
        ref="discountCreateVal"
      >
        <div class="mb-2">
          <b-row>
            <div class="ml-1">
              <span class="font-weight-bolder font-medium-2 d-block ml-2 mb-sm-1">
                {{ $t('Discount Reach') }}
              </span>
              <b-form-group
                v-slot="{ ariaDescribedby }"
              >
                <b-form-radio
                  v-for="radio in listDiscountTypes"
                  :key="radio.id"
                  v-model="selectedDiscountType"
                  class="mb-1"
                  :aria-describedby="ariaDescribedby"
                  :name="radio.name"
                  :value="radio.id"
                  @change="onRadioChanges($event)"
                >
                  <span>
                    {{ $t(radio.name) }}
                  </span>
                </b-form-radio>
              </b-form-group>
            </div>
          </b-row>
          <b-row>
            <b-col md="6">
              <div>
                <component
                  :is="discountsFields[field].type"
                  v-for="field in ['name']"
                  :key="field"
                  v-model="discounts[field]"
                  v-bind="getProps(field)"
                />
              </div>
            </b-col>
            <b-col
              md="6"
            >
              <div>
                <component
                  :is="discountsFields[field].type"
                  v-for="field in ['rate']"
                  :key="field"
                  v-model="discounts[field]"
                  v-bind="getProps(field)"
                />
              </div>
            </b-col>
          </b-row>
          <b-row v-if="parseInt(selectedDiscountType) === 2">
            <b-col md="12">
              <div>
                <l-table-list-collector
                  ref="lTableRef"
                  :module-name="MODULE_NAME"
                  :fetched-data="fetchedSubmitData()"
                  :table-columns="tableColumnsDiscountProducts"
                  :table-suggestion-configs="autoSuggestTableColumns"
                  :has-below-actions="true"
                  :table-config-options="{
                    endpoint: `${MODULE_NAME}/getProducts`
                  }"
                  autosuggest-list-item-name="name"
                  class="rental-sales-table"
                  @getCollectedList="getCollectedList"
                >
                  <template #cell(action)="{ data }">
                    <div class="d-flex align-items-center mx-md-n2 ">
                      <feather-icon
                        icon="LEyeIcon"
                        size="22"
                        class="cursor-pointer mr-1 ml-2 border-dotted feather-trash-icon defaultIconColor"
                        @click="showProduct(data.item.id, $event)"
                      />
                      <feather-icon
                        icon="LTrashIcon"
                        size="22"
                        class="cursor-pointer mr-1 border-dotted feather-trash-icon defaultIconColor"
                        @click="remove(data.item.id)"
                      />
                    </div>
                  </template>
                  <template #cell(sku)="{data}">
                    <div class="text-start">
                      {{ data.item.sku }}
                    </div>
                  </template>
                  <template #cell(name)="{data}">
                    <div
                      class="cell-row-name"
                      :title="data.item.name"
                    >
                      {{ data.item.name }}
                    </div>
                  </template>
                  <template #cell(products)="{data}">
                    <div
                      class="cell-row-name"
                      :title="data.item.categories"
                    >
                      {{ data.item.categories.map(category => category.name).join(', ') }}
                    </div>
                  </template>
                </l-table-list-collector>
              </div>
            </b-col>
          </b-row>
          <b-row v-else>
            <b-col md="6">
              <div v-if="hasKey('categories')">
                <component
                  :is="discountsFields['categories'].type"
                  ref="categories_ref"
                  v-model="discounts.categories"
                  v-bind="getProps('categories')"
                />
              </div>
              <div>
                <component
                  :is="discountsFields['brands'].type"
                  ref="brands_ref"
                  v-model="discounts.brands"
                  v-bind="getProps('brands')"
                  :selected-values="returnBrands()"
                />
              </div>
            </b-col>
            <b-col md="6">
              <div>
                <component
                  :is="discountsFields['tags'].type"
                  v-if="hasKey('tags')"
                  ref="tags_ref"
                  v-model="discounts['tags']"
                  v-bind="getProps('tags')"
                  :selected-values="returnTags('tags')"
                />
              </div>
              <div>
                <h1 class="text-left font-medium-3 font-weight-small">
                  If more than one field is completed (category, brand, tags), the system will apply the discount only items that meet all the specified criteria in these fields. (i.e. cameras tagged as digital)
                </h1>
              </div>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              md="6"
            >
              <div>
                <component
                  :is="discountsFields[field].type"
                  v-for="field in ['start', 'end']"
                  :key="field"
                  v-model="discounts[field]"
                  v-bind="getProps(field)"
                />
              </div>
            </b-col>
          </b-row>
        </div>
        <portal to="body-footer">
          <div class="d-flex mt-2 pb-1 justify-content-between">
            <div>
              <b-button
                v-if="isFormChanged && isAvailID"
                class="cancelBtn font-medium-1 font-weight-bolder"
                variant="outline-primary"
                @click="loader"
              >
                <feather-icon
                  icon="LCancelIcon"
                  size="16"
                />
                {{ $t('Cancel') }}
              </b-button>
              <b-button
                v-else
                class="cancelBtn font-medium-1 font-weight-bolder"
                variant="outline-primary"
                @click="cancel"
              >
                {{ $t('Back to List') }}
              </b-button>
            </div>
            <div>
              <b-button
                variant="success"
                class="saveBtn font-medium-1 font-weight-bolder"
                :disabled="isCreateForm ? false : !isFormChanged"
                @click="submit"
              >
                {{ $t('SAVE') }}
              </b-button>
            </div>
          </div>
        </portal>
      </validation-observer>
    </b-form>
    <b-s-warning-modal ref="confirm-modal" />
  </div>
  <div v-else>
    <skeleton />
  </div>
</template>
<script>
// eslint-disable-next-line import/no-cycle
import { required } from '@/libs/validations/validations'
import { scrollToError } from '@core/utils/utils'
import { ValidationObserver } from 'vee-validate'
import {
  BButton, BRow, BCol, BForm, VBToggle, BFormGroup, BFormRadio, BFormCheckbox, BFormInput,
} from 'bootstrap-vue'
import ErrorAlert from '@/views/components/ErrorAlert.vue'
import LTableListCollector from '@/views/components/LTableListCollector.vue'
import config from '../pricingConfig'
import Skeleton from './Skeleton.vue'

export default {
  name: 'DiscountsForm',
  components: {
    BFormInput,
    BFormCheckbox,
    LTableListCollector,
    BFormRadio,
    BFormGroup,
    BButton,
    BRow,
    BCol,
    BForm,
    ErrorAlert,
    ValidationObserver,
    Skeleton,
  },
  directives: {
    'b-toggle': VBToggle,
  },
  props: {
    isCreateForm: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      required,
      error: {},
      loading: false,
      onSubmit: false,
      pricingRule: null,
      selectedDiscountType: null,
      isAvailID: this.$route.params.id,
    }
  },
  computed: {
    getCategory() {
      return this.$store.state.listModule.categoriesList
    },
    getBrands() {
      return this.$store.state.listModule.brandsList
    },
    getTags() {
      return this.$store.state.listModule.categoriesList
    },
    discounts() {
      console.log('FORM@: ', this.$store.state[this.MODULE_NAME].discountsForm)
      return this.$store.state[this.MODULE_NAME].discountsForm
    },
    discount() {
      const data = this.$store.state[this.MODULE_NAME].discount
      this.selectedDiscountType = (data?.type && this.isAvailID) ? data?.type : 1
      console.log('SHU: ', data)
      return data
    },
    discountsClone() {
      return this.$store.state[this.MODULE_NAME_CLONE].settingCatalogDiscountsForm
    },
    isFormChanged() {
      console.log('data 1', JSON.stringify(this.discounts))
      console.log('data 2', this.discountsClone)
      return JSON.stringify(this.discounts) !== this.discountsClone
    },
    listDiscountTypes() {
      return this.$store.state[this.MODULE_NAME].discountTypes
    },
  },
  created() {
    this.$store.dispatch(`${this.MODULE_NAME}/discountTypes`)
    this.$store.commit(`${this.MODULE_NAME}/SET_FORM`, {})
  },
  methods: {
    hasKey(key) {
      // eslint-disable-next-line no-prototype-builtins
      return this.discountsFields.hasOwnProperty(key)
    },
    returnCategories() {
      return this.getCategory?.map(category => category.id) ?? []
    },
    returnTags(tagsArrName) {
      return this.discount[tagsArrName]?.map(tag => tag?.label)
    },
    returnBrands() {
      return this.getBrands?.map(brand => ({ id: brand?.id, label: brand?.label })) ?? []
    },
    fetchedSubmitData() {
      return this.discounts.products
    },
    remove(data) {
      this.discounts.products = this.discounts.products.filter(product => product.id !== data)
      this.fetchedSubmitData()
    },
    showProduct(id, e) {
      if (e.ctrlKey) {
        const route = this.$router.resolve({ name: 'home-catalog-view', params: { id } })
        window.open(route.href)
      } else {
        this.$router.push({ name: 'home-catalog-view', params: { id } })
      }
    },
    getCollectedList(list) {
      this.$store.commit(`${this.MODULE_NAME}/SET_FORM`, {
        products: list,
      })
    },
    onRadioChanges(value) {
      this.selectedDiscountType = value
    },
    showConfirmationModal(next) {
      if (this.$refs['confirm-modal']) this.$refs['confirm-modal'].confirmWarning(next, this.isFormChanged, this.onSubmit)
    },
    submit() {
      this.error = {}
      this.onSubmit = true
      console.log('CATEGORIES: ', this.discounts.categories)
      console.log('BRANDS: ', this.discounts.returnBrands)
      console.log('TAGS: ', this.discounts.returnTags)
      console.log('SUBMIT', this.discounts)
      this.$refs.discountCreateVal.validate()
        .then(success => {
          if (success) {
            const data = this.discounts
            const discountId = this.discounts.id
            const defaultDiscounts = this.mappingFields(Object.keys(this.discountsFields), data, {
              id: discountId,
              name: data.name,
              rate: data.rate,
              start: new Date(data.start),
              end: new Date(data.end),
              type: this.selectedDiscountType,
              brands: data.brands,
              categories: data.categories,
              tags: data.tags,
              products: data.products?.map(item => item.id),
            })
            console.log('defaultDiscounts', defaultDiscounts)
            this.sendNotification(this, { ...defaultDiscounts, discountId }, `${this.MODULE_NAME}/${this.discounts.id ? 'update' : 'create'}`)
              .then(res => {
                this.clear()
                // this.$store.dispatch(`${this.MODULE_NAME}/get`, this.$route.params.id)
                this.$router.replace({
                  name: 'settings-catalog-price-list',
                  params: { id: res.data.data?.id },
                })
              })
              .catch(err => {
                this.error = err.response?.data
              }).finally(() => {
                this.onSubmit = false
              })
          } else {
            scrollToError(this, this.$refs.discountCreateVal)
          }
        })
    },
    clear() {
      this.$refs.discountCreateVal.reset()
      this.$store.commit(`${this.MODULE_NAME}/SET_FORM`, {})
    },
    cancel() {
      this.$router.push({ name: 'settings-catalog-price-list' })
    },
    loader() {
      this.$refs.discountCreateVal.reset()
      const smth = JSON.parse(this.discountsClone)
      this.$store.commit(`${this.MODULE_NAME}/SET_FORM`, smth)
    },
    getProps(fieldName) {
      return {
        is: this.discountsFields[fieldName].type,
        field: this.discountsFields[fieldName],
        name: fieldName,
      }
    },
  },
  setup() {
    const MODULE_NAME = 'discounts'
    const CATALOG_MODAL_NAME = 'catalogs'
    const MODULE_NAME_CLONE = 'cloneData'
    const { discountsFields, tableColumnsDiscountProducts, autoSuggestTableColumns } = config()
    return {
      MODULE_NAME,
      MODULE_NAME_CLONE,
      tableColumnsDiscountProducts,
      autoSuggestTableColumns,
      CATALOG_MODAL_NAME,
      discountsFields,
    }
  },
}
</script>
<style scoped>

</style>
