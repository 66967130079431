<template>
  <discounts-form
    v-if="discounts"
    ref="discounts-form"
    :is-loading="loading"
  />
  <div v-else>
    <component-not-found
      title="Discounts List"
      :button-link="{ name: 'settings-catalog-price-list'}"
    />
  </div>

</template>
<script>
import config from '../pricingConfig'
import DiscountsForm from '../components/DiscountsForm.vue'

export default {
  name: 'DiscountsUpdate',
  components: {
    DiscountsForm,
  },
  inject: ['discountsModuleName'],
  data() {
    return {
      error: false,
      loading: true,
    }
  },
  computed: {
    discounts() {
      return this.$store.state[this.discountsModuleName].discountsForm
    },
  },
  mounted() {
    this.$store.dispatch(`${this.discountsModuleName}/get`, this.$route.params.id).then(res => {
      const { data } = res.data
      const discounts = this.mappingFields(Object.keys(this.discountsFields), data, {
        id: data.id,
        name: data.name,
        rate: data.rate,
        start: new Date(data.start),
        end: new Date(data.end),
        type: data.type,
        tags: data.tags.map(wh => wh.name),
        brands: data.brands.map(wh => wh.name),
        categories: data.categories.map(wh => wh.name),
        products: data.products,
      })
      console.log('DISC DATA @', discounts)
      this.$store.commit(`${this.discountsModuleName}/SET_FORM`, discounts)
      this.$store.commit(`${this.MODULE_NAME_CLONE}/SET_SETTING_DISCOUNTS_FORM_CLONE`, discounts)
    }).catch(err => {
      this.error = true
      this.errorNotification(this, err)
    }).finally(() => {
      this.loading = false
    })
  },
  created() {
    // this.$store.commit(`${this.discountsModuleName}/SET_FORM`, {})
  },
  beforeRouteLeave(to, from, next) {
    if (this.$refs['discounts-form']) this.$refs['discounts-form'].showConfirmationModal(next)
  },
  setup() {
    const MODULE_NAME_CLONE = 'cloneData'
    const MODULE_NAME = 'discounts'
    const { discountsFields } = config()
    return {
      MODULE_NAME_CLONE,
      MODULE_NAME,
      discountsFields,
    }
  },
}
</script>
